"use strict";

/* exported
unionIconDirective
 */

function unionIconDirective() {
  return {
    restrict: "E",
    template: `<svg width="12" height="13" viewBox="0 0 12 13" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd"
clip-rule="evenodd"
d="M1.10526 0.5C0.494843 0.5 0 0.994843 0 1.60526V11.3947C0 12.0052 0.494843 12.5 1.10526 12.5H6C6.55228 12.5 7 12.0523 7 11.5C7 10.9477 6.55228 10.5 6 10.5H2.29412C2.13168 10.5 2 10.3683 2 10.2059V2.79412C2 2.63168 2.13168 2.5 2.29412 2.5H6C6.55228 2.5 7 2.05228 7 1.5C7 0.947715 6.55228 0.5 6 0.5H1.10526ZM7.62186 4.67117C7.27255 4.29416 7.2773 3.71031 7.63271 3.33904C8.01801 2.93654 8.66218 2.93989 9.04328 3.34636L11.6794 6.15799C11.8597 6.35032 11.8597 6.64962 11.6794 6.84196L9.04328 9.65362C8.66218 10.0601 8.01801 10.0634 7.6327 9.66095C7.2773 9.28968 7.27254 8.70584 7.62185 8.32882L8.38975 7.5H5C4.44772 7.5 4 7.05229 4 6.5C4 5.94772 4.44772 5.5 5 5.5H8.38978L7.62186 4.67117Z"
fill="{{ fill }}"/>
</svg>`,
    scope: {
      fill: "@",
      },
  };
}

"use strict";

/* exported
asterikIconDirective
 */

function asterikIconDirective() {
  return {
    restrict: "E",
    template: `<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.69365 7.6766C4.71438 8.12507 4.35639 8.5 3.90744 8.5C3.4585 8.5 3.1005 8.12507 3.12124 7.6766L3.214 5.67042L1.47702 6.76546C1.10894 6.9975 0.622076 6.88201 0.397441 6.50937C0.165623 6.12481 0.307161 5.62438 0.706056 5.41821L2.50748 4.48714L0.712 3.57734C0.310915 3.3741 0.16699 2.87292 0.399125 2.48784C0.622836 2.11673 1.1077 2.00172 1.47426 2.2328L3.214 3.32958L3.12124 1.3234C3.1005 0.874933 3.4585 0.5 3.90744 0.5C4.35639 0.5 4.71438 0.874932 4.69365 1.3234L4.60088 3.32958L6.34063 2.2328C6.70719 2.00172 7.19205 2.11673 7.41576 2.48784C7.6479 2.87292 7.50397 3.3741 7.10288 3.57734L5.3074 4.48714L7.10883 5.41821C7.50772 5.62438 7.64926 6.12481 7.41744 6.50937C7.19281 6.88201 6.70594 6.9975 6.33787 6.76546L4.60088 5.67042L4.69365 7.6766Z"
fill="{{ fill }}"/>
</svg>
`,
    scope: {
      fill: "@",
    },
  };
}
